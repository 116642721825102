import React, { useState, useEffect, FormEvent } from 'react';
import { useParams } from 'react-router-dom';
import { Game, Prediction } from '../interfaces';
import { Api } from '../services/api';
import moment from 'moment';
import { Alert, Box, Button, FormControl, LinearProgress, Paper, Stack, styled, TextField, Typography } from '@mui/material';
import { PredictionApi } from '../services/predictions';

import PredictionView from './Prediction';
import Markdown from 'react-markdown';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

function GameDetails({ api, predictionApi }: { api: Api, predictionApi: PredictionApi }) {
  const { gameId } = useParams();
  const [game, setGame] = useState<Game | null>(null);
  const [specialRequest, setSpecialRequest] = useState<string>("");
  const [result, setResult] = useState<Prediction | null>(null);
  const [resultString, setResultString] = useState<string | null>(null);
  const [error, setError] = useState<Error | string | null>(null);
  const [fromCache, setFromCache] = useState<boolean>(false);
  var gameIdInt = parseInt(gameId!)!;
  const [predicting, setPredicting] = useState<boolean>(false);

  useEffect(() => {
    api.getGame(gameIdInt)
      .then(response => setGame(response))
      .catch(error => console.error(error));
  }, [gameIdInt, api]);


  const LoadingBox = () => <Box sx={{ width: '100%' }}>
    <LinearProgress />
  </Box>;

  if (!game) {
    return <LoadingBox />;
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    setPredicting(true);
    setError(null);
    event.preventDefault();
    try {
      var response = await predictionApi.predict(gameIdInt, specialRequest)

      console.log(response)
      if (response.response_str)
        setResultString(response.response_str)
      else if (response.response)
        setResult(response.response)
      setFromCache(response.from_cache)
    }
    catch (err: any) {
      console.error(err)
      setError(err)
    }
    finally {
      setPredicting(false)
    }
  }

  return (
    <Stack spacing={2}>
      <Item>
        <h2>{game.away_team} (Away) @ {game.home_team} (Home)</h2>
        <p>Date: {moment(game.game_date).format("MMMM Do YYYY @ h:mm a")}</p>
      </Item>
      <Item>
        <h2>Special Request</h2>

        <label>Do you want to request anything special?</label>
        <FormControl fullWidth sx={{ p: 1 }}>
          <TextField multiline value={specialRequest} label="Special Request"
            maxRows={4}
            onChange={e => setSpecialRequest(e.target.value)} />
        </FormControl>
      </Item>
      <Item>
        <form onSubmit={handleSubmit}>
          <Button type="submit" variant="contained" disabled={predicting}>Generate</Button>
        </form>
      </Item>
      <Item>
        <div>
          <h3>Results</h3>
          {
            predicting ? (<><p>Predicting...</p>
              <LoadingBox /></>) :
              error ?
                <Alert severity="error">
                  Uh oh! Something went wrong...
                </Alert>
                : !result && !resultString ?
                  <p>You haven't run any results yet</p>
                  :
                  <Typography align='left'>
                    <Stack sx={{ width: '100%' }} spacing={2}>
                      {fromCache && <Alert severity="info">
                        This information was retrieved from cache, and may be outdated.
                      </Alert>
                      }
                      <Paper>
                        {resultString ?
                          <Markdown>{resultString}</Markdown> :
                          result ?
                          <PredictionView gameData={result} /> : <div></div>
                        }
                      </Paper>
                    </Stack>
                  </Typography>
          }
        </div>
      </Item>
    </Stack>
  );
}

export default GameDetails;
