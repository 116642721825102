import React from 'react';
import { User } from '../interfaces';

const buildDate = process.env.REACT_APP_FIREBASE_BUILD_DATE
const sha = process.env.REACT_APP_FIREBASE_BUILD_SHA

function UserProfile({ user }: { user: User | null }) {
    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>User Profile</h1>
            <p>Username: {user.username}</p>
            <p>Email: {user.email}</p>
            {/* Add more user details as needed */}
            <p>Build: {sha || "N/A"}</p>
            <p>Build Date: {buildDate || "N/A"}</p>
        </div>
    );
}

export default UserProfile;
