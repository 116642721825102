import * as React from 'react';
import { Box, Typography, Card, CardContent, Stack } from '@mui/material';
import { Prediction } from '../interfaces'

const PredictionView: React.FC<{ gameData: Prediction }> = ({ gameData }) => {
    var response = gameData;
    return <Stack spacing={2}>

        {
            (response.spread || response.summary || response.total_points || response.over_under) &&
            <Card>
                <CardContent>
                    <Box margin={1}>
                        {response.summary && <Typography variant="body1" gutterBottom ><b>Summary:</b> {response.summary}</Typography>}
                        {response.spread && <Typography variant="body1"><b>Spread:</b> {response.spread.projected} ({response.spread.team_to_take}) - <i>Likelihood: {response.spread.likelihood}%</i></Typography>}
                        {response.total_points && <Typography variant="body1"><b>Total Points:</b> {response.total_points.away_team_total_points} (Away) - {response.total_points.home_team_total_points} (Home) - <i>Likelihood: {response.total_points.likelihood}%</i></Typography>}
                        {response.over_under && <Typography variant="body1"><b>Over/Under:</b> {response.over_under.projected} - <i>Likelihood: {response.over_under.likelihood}%</i></Typography>}
                    </Box>
                </CardContent>
            </Card>
        }
        {
            (response.weather_conditions) &&
            <Card>
                <CardContent>
                    <Box margin={1}>
                        {response.weather_conditions.temperature && <Typography variant="body1"><b>Temperature:</b> {response.weather_conditions.temperature}</Typography>}
                        {response.weather_conditions.precipitation && <Typography variant="body1"><b>Precipitation:</b> {response.weather_conditions.precipitation}</Typography>}
                        {response.weather_conditions.wind && <Typography variant="body1"><b>Wind:</b> {response.weather_conditions.wind}</Typography>}
                        {response.weather_conditions.impact && <Typography variant="body1"><b>Impact:</b> {response.weather_conditions.impact}</Typography>}
                    </Box>
                </CardContent>
            </Card>
        }

        {(response.anytime_touchdowns || []).length > 0 &&
            <Card >
                <CardContent>
                    <Box margin={1}>
                        <Typography gutterBottom variant="h4">Anytime Touchdowns</Typography>
                        {
                            response.anytime_touchdowns.map(at => <Typography variant="body1">{at.player} - <i>Likelihood: {at.likelihood}%</i></Typography>)
                        }
                    </Box>
                </CardContent>
            </Card>
        }

        {(response.parlays || []).length > 0 &&
            <Card >
                <CardContent>
                    <Box margin={1}>
                        <Typography gutterBottom variant="h4">Suggested Parlays</Typography>
                        {
                            response.parlays.map((p, k) =>
                                <Box marginY={1}>
                                    <Typography key={k} gutterBottom variant="h5">{p.description}</Typography>
                                    <Stack spacing={2}>
                                        {
                                            p.legs.map((l, i) => <Box key={i}>
                                                <Typography variant="body1">{l.type.toLocaleUpperCase()} - {l.player ? `${l.player} (${l.team})` : l.team} - <i>Likelihood: {l.likelihood}%</i></Typography>
                                                <Typography variant="body2">Projected: {l.projected}</Typography>
                                                <Typography variant="body2">Reasoning: {l.reasoning}</Typography>
                                            </Box>)
                                        }
                                    </Stack>
                                </Box>
                            )}
                    </Box>
                </CardContent>
            </Card>
        }
    </Stack >
};

export default PredictionView;

